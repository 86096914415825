<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/task.png" alt />
        <span class="center">工资包</span>
        <img src="../../assets/right.png" alt />
        <span class="center">工资包列表</span>
      </div>
      <div style="display: flex; align-items: center">
        <el-button
          @click="addObject"
          size="small"
          type="primary"
          icon="el-icon-plus"
          v-access
          data-ctrl="salaryTaskAdd"
        >新建</el-button>
      </div>
    </div>
    <el-card class="box-card">
      <el-row type="flex" class="row-bg" justify="end" style="margin-bottom: 20px">
        <el-col :span="8" style="text-align: left">
          <span class="seach_text">关键字：</span>
          <el-input
            placeholder="请输入工资包名称"
            v-model="formInline.keywords"
            @input="loadData"
            size="mini"
            style="margin-right: 10px; width: 250px"
            prefix-icon="el-icon-search"
            clearable
          ></el-input>
        </el-col>
        <el-col :span="8">
          <span class="seach_text">工资包状态：</span>

          <el-select
            :value="formInline.state"
            @change="stateChange"
            style="width: 230px"
            size="mini"
            clearable
          >
            <el-option
              v-for="(item, index) in stateList"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <span class="seach_text">项目：</span>

          <el-select
            :value="formInline.projectCode"
            @change="projectCodeChange"
            filterable
            style="width: 230px"
            size="mini"
            clearable
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="(item, index) in projectData"
              :key="index"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-col>
        <!-- <el-col :span="8" style="text-align: right">
          <span class="seach_text">结算中心：</span>

          <el-select
            :value="formInline.pay_platform"
            @change="platformChange"
            style="width: 230px"
            size="mini"
            clearable
          >
            <el-option
              v-for="(item, index) in platformList"
              :key="index"
              :label="item.shortname"
              :value="item.pay_platform + ''"
            ></el-option>
          </el-select>
        </el-col>-->
      </el-row>

      <el-table :data="list" style="width: 100%" default-expand-all>
        <el-table-column label="工资包号">
          <template slot-scope="scope">
            <span>{{ scope.row.code }}</span>
          </template>
        </el-table-column>
        <el-table-column label="工资包名称">
          <template slot-scope="scope">
            <div style="display:flex;align-items:center">
              <span style="color:#ec808d;margin-right:3px">{{scope.row.isTax ? "★" : "" }}</span>
              <span
                class="clickColor"
                @click="goDetail({ code: scope.row.code })"
              >{{ scope.row.name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="归属项目">
          <template slot-scope="scope">
            <span v-if="scope.row.project">{{ scope.row.project.name }}</span>
          </template>
        </el-table-column>

        <el-table-column label="结算金额（元）">
          <template slot-scope="scope">
            {{
            scope.row.total === "0" ? "0" : scope.row.total
            }}
          </template>
        </el-table-column>

        <el-table-column label="人数">
          <template slot-scope="scope">{{ scope.row.makerNumber }}</template>
        </el-table-column>
        <el-table-column label="支付银行">
          <template slot-scope="scope">
            <span v-if="scope.row.state != '0'">{{ scope.row.payInfo.bank_name}}</span>
            <span v-else>待核查</span>
          </template>
        </el-table-column>
        <el-table-column label="发包时间" width="160">
          <template slot-scope="scope">
            {{
            scope.row.releaseTime | dateVal("YYYY-MM-DD")
            }}
          </template>
        </el-table-column>
        <el-table-column label="工资包状态" width="240">
          <template slot-scope="scope">
            <el-progress
              :percentage="scope.row.percent"
              :color="scope.row.color"
              :format="formatText"
            ></el-progress>
            <div style="display: flex; justify-content: space-between">
              <!-- <el-button
                type="text"
                @click="editTask(scope.row)"
                v-access
                data-ctrl="editSalaryTask"
              >修改包</el-button>-->
              <el-button
                type="text"
                v-show="scope.row.state === '0'"
                @click="checkInfo({ code: scope.row.code,show:true })"
              >核查信息</el-button>
              <!-- <el-button
                type="text"
                @click="publishTask(scope.row.code)"
                v-access
                data-ctrl="salaryTaskPublish"
                v-show="scope.row.state === '0'"
              >发布</el-button>-->

              <el-button
                type="text"
                @click="checkSalaryTask(scope.row.code)"
                v-show="scope.row.state === '2'"
                v-if="scope.row.project.taskReviewer === approver"
              >复核</el-button>

              <el-button
                v-if="scope.row.approver &&
                scope.row.state === '1' &&
                scope.row.approver.find(
                  (it) => it.viewerCode === approver && it.status === '0'
                )"
                type="text"
                @click="check(scope.row)"
              >审核</el-button>

              <!-- <el-popover
                placement="top"
                width="400"
                trigger="manual"
                :ref="'popover-' + scope.row.id"
              >
                <div class="del-pop">
                  <img src="../../assets/submit.png" alt />
                </div>
                <div class="del-title">结算发起</div>

                <div class="del-btn">
                  <el-button type="primary" @click="settle(scope.row)" plain>确认</el-button>
                  <el-button type="info" @click="doCancel(scope.row.id)" plain>取消</el-button>
                </div>
                <el-button
                  slot="reference"
                  type="text"
                  v-access
                  data-ctrl="settleSalaryTask"
                  v-show="scope.row.state === '4'"
                  :loading="scope.row.loading"
                  @click="settle(scope.row.id)"
                >结算</el-button>
              </el-popover>-->

              <!-- <el-popover
                placement="top"
                width="400"
                trigger="manual"
                :ref="'popoverTwo-' + scope.row.id"
              >
                <div class="del-pop">
                  <img src="../../assets/del.png" alt />
                </div>
                <div class="del-title">撤销工资包</div>
                <div class="del-sumtitle">确认撤销作废该工资包？</div>
                <div class="del-btn">
                  <el-button type="primary" @click="del(scope.row)" plain>确认</el-button>
                  <el-button type="info" @click="doCancel(scope.row.id)" plain>取消</el-button>
                </div>
                
              </el-popover>-->
              <el-button
                slot="reference"
                type="text"
                v-show="
                  scope.row.state === '0' ||
                  scope.row.state === '1' ||
                  scope.row.state === '2'
                "
                v-access
                data-ctrl="salaryTaskDel"
                style="color: #f56c6c"
                @click="revoke(scope.row.code)"
              >撤销</el-button>
              <el-button
                slot="reference"
                type="text"
                v-show="
                  scope.row.state === '5'
                "
                v-access
                data-ctrl="delSalaryTask"
                style="color: #f56c6c"
                @click="getDel(scope.row)"
              >撤销原因</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin: 0 10px">每页{{ pageSize }}条</span>
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>

    <CheckTask :code="code" :checkVisible.sync="checkVisible" @checkClose="loadData"></CheckTask>
    <el-dialog title="复核提示" :visible.sync="checkDialogVisible" width="500px" :show-close="false">
      <el-form :rules="checkRules" ref="checkForm" :model="checkForm" label-width="80px">
        <el-form-item label="验证码" prop="smsCode">
          <div style="display: flex">
            <el-input v-model="checkForm.smsCode" placeholder="请输入验证码"></el-input>
            <el-button
              type="info"
              plain
              title="验证码将发送至复核人手机"
              style="border-left: none"
              @click="sendSmsCode"
              v-if="!isSend"
            >获取验证码</el-button>
            <el-button
              type="info"
              plain
              title="验证码将发送至复核人手机"
              style="border-left: none"
              v-if="isSend"
            >{{ seconds }}S</el-button>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog('checkForm')">取 消</el-button>
        <el-button type="primary" @click="confirmCheck('checkForm')">确定复核</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//接口：
import { api } from '/src/api/base';
// 查询项目列表
export const projectList = api()('project.list.json');
//查询工资包列表
export const salaryTaskList = api()('salaryTask.list.json');
//发布工资包
export const salaryTaskPublish = api()('salaryTask.publish.json');
//删除工资包
export const PackageDel = api()('salaryTask.del.json');
//发送复核验证码
export const salaryTaskSendCheckCode = api()('salaryTask.send.reviewCode.json');
//复核工资包
export const salaryTaskCheck = api()('salaryTask.startSettlement.json');
// 结算工资包
export const salaryTaskStartSettlement = api()('salaryTask.startSettlement.json');
export default {
  components: {
    CheckTask: () => import('./checkTask.vue'),
  },
  data () {
    return {
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      formInline: {
        keywords: '',

        state: '',
        projectCode: ''
      },

      // "1审核中 2复核中 3被驳回 4已完成 5已撤销"
      stateList: [
        {
          value: '',
          label: '全部',
        },
        {
          value: '0',
          label: '待核查',
        },
        {
          value: '1',
          label: '审批中',
        },
        {
          value: '2',
          label: '复核中',
        },
        {
          value: '3',
          label: '被驳回',
        },
        {
          value: '4',
          label: '已完成',
        },
        // {
        //   value: '5',
        //   label: '已撤销',
        // }
      ],
      list: [],
      code: '',
      approver: '',
      checkVisible: false,
      checkFileName: '',
      checkFileData: '',
      projectData: [],

      checkDialogVisible: false,
      checkForm: {
        smsCode: '',
      },
      checkRules: {
        smsCode: [
          { required: true, message: '请输入获取到的手机验证码', trigger: 'blur' },
        ]
      },

      seconds: 60,
      isSend: false
    };
  },
  watch: {

  },
  async created () {

    this.approver = window.localStorage.getItem('code')

    await this.loadData();
    await this.projectList()
  },

  methods: {
    revoke (id) {
      // this.$refs['popover-' + id].doShow();
      this.$prompt('请输入备注', '确定撤销当前工资包交易？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputErrorMessage: '请输入备注',
      })
        .then(({ value }) => {
          const params = {
            code: String(id),
            cancelMsg: value,
          };
          PackageDel(params).then((res) => {
            console.log(res, 111);
            this.loadData();
            this.$message({
              type: 'success',
              message: '撤销成功',
            });
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消',
          });
        });
    },
    // 撤销工资包
    del (obj) {
      PackageDel({ code: obj.code }).then((res) => {
        console.log(res);
        this.$message.success('工资包成功删除！');
        this.doCancel(obj.id);
        this.loadData();
      });
    },
    // 取消prpver弹窗
    doCancel () {
      Object.keys(window._list).forEach((it) => {
        window._list[it].doClose();
      });
    },
    getDel (row) {
      const text = row.cancelMsg
      this.$alert(`${text}`, '撤销原因 ', {
        confirmButtonText: '确定',
        callback: action => {
          console.log(action);
          // this.$message({
          //   type: 'info',
          //   message: `action: ${ action }`
          // });
        }
      });
    },

    stateChange (val) {
      this.pageNumber = 1;
      this.formInline.state = val;
      this.loadData();
    },
    projectCodeChange (val) {
      this.pageNumber = 1;
      this.formInline.projectCode = val;
      this.loadData();
    },
    formatText (row) {

      const map = {
        0: '未核查',
        10: '已撤销',
        25: '审批中',
        50: '被驳回',
        75: '复核中',
        100: '已完成',
      };

      return map[row];
    },
    check (row) {
      this.code = row.code;
      this.checkVisible = true;
    },

    async settle (row) {
      row.loading = true;
      await salaryTaskStartSettlement({
        code: row.code,
      });
      row.loading = false;
      this.$message.success('结算成功');
      this.doCancel(row.id);
      this.loadData();
    },
    async projectList () {
      const res = await projectList({
        enterpriseCode: window.localStorage.getItem('enterpriseCode'),
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        keywords: this.keywords
      });
      this.projectData = res.list

    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.loadData();
    },
    async loadData () {
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        enterpriseCode: window.localStorage.getItem('enterpriseCode'),
        ...this.formInline,
        $ignoreRepeat: true,
      };
      const res = await salaryTaskList(params)
      this.list = res.list.map((it) => {
        // 0: '待审核',1: '审核中', 2: '待复核'3: '拒绝',  4: '待结算',5: '已完成', 6: '已取消', 7: "结算中"
        //  0: '已撤销',
        //   25: '被驳回',
        //   50: '审核中',
        //   75:'复核中',
        //   100: '已完成',
        return {
          ...it,
          loading: false,
          color: it.state === '5' ? '#909399' :
            it.state === '3' ? '#f56c6c' : '#409eff',
          percent:
            it.state === '0' ? 0 :
              it.state === '5' ? 10 :
                it.state === '1' ? 25 :
                  it.state === '3' ? 50 :
                    it.state === '2' ? 75 :
                      it.state === '4' ? 100 :
                        100
        };
      });
      this.total = res.total;
    },

    async publishTask (code) {
      await salaryTaskPublish({ code })
      this.$message.success('工资包发布成功！')
      this.loadData()
    },
    addObject () {
      this.$router.push({
        path: '/system/task/add',
        query: {
          type: 'add',
        },
      });
    },
    editTask (row) {
      this.$router.push({
        path: '/system/task/add',
        query: {
          type: 'edit',
          code: row.code,
        },
      })
    },
    goDetail (obj) {
      this.$router.push({
        path: '/system/task/detail',
        query: obj,
      });
    },
    checkInfo (obj) {
      this.$router.push({
        path: '/system/task/detail',
        query: obj,
      });
    },
    async sendSmsCode () {
      this.isSend = true;
      await salaryTaskSendCheckCode({ code: this.code });
      const time = setInterval(() => {
        this.seconds--
        if (this.seconds === 0) {
          clearInterval(time)
          this.seconds = 60
          this.isSend = false;
        }
      }, 1000)
    },
    checkSalaryTask (code) {
      this.checkDialogVisible = true;
      this.code = code;

    },
    closeDialog (form) {
      this.$refs[form].resetFields();
      this.checkDialogVisible = false;
    },
    confirmCheck (form) {
      this.$refs[form].validate(async valid => {
        if (valid) {
          await salaryTaskCheck({
            code: this.code,
            smsCode: this.checkForm.smsCode,
          });
          this.closeDialog(form)
          this.loadData()
          this.$message.success('工资包复核成功 ')
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.clickColor {
  color: #409eff;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>
